<template>
  <v-card width="400" class="mx-auto mt-auto mb-auto" @keydown.enter="enterPressed">
    <v-card-title>
      <h2 class="display">Login</h2>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form" v-model="isFormValid">
        <v-text-field v-model="loginForm.email" label="Email" prepend-icon="mdi-account-circle"
                      :rules="rules.emailRules" outlined autocomplete="username">
        </v-text-field>
        <v-text-field v-model="loginForm.pwd" label="Password" prepend-icon="mdi-lock" outlined
                      :type="showPassword? 'text' : 'password'"
                      :append-icon="showPassword? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.pwdReq]"
                      autocomplete="current-password"
                      @click:append="showPassword = !showPassword">
        </v-text-field>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-snackbar v-model="snackbar" timeout="-1" :absolute=true>
      Authorization failed. Check your email and password.
      <template v-slot:action="{ attrs }">
        <v-btn depressed small v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-card-actions>
      <v-btn color="accent" >Register</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" :disabled="!isFormValid" v-on:click="doLogin()">Login</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "LoginPage",
  data: () => ({
    snackbar: false,
    isFormValid: false,
    showPassword: false,
    loginForm: {
      email: "",
      pwd: ""
    },
    rules: {
      emailRules: [
        v => !!v || 'E-mail is required.',
        v => /.+@.+/.test(v) || 'E-mail must be valid.'
      ],
      min: v => v.length >= 8 || 'Min 8 characters',
      pwdReq: v => !!v || 'Password is required.',
    },
  }),
  methods: {
    enterPressed() {
      if (this.isFormValid) {
        this.doLogin()
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    doLogin: function () {
      let cred = {}
      cred.email = this.loginForm.email
      cred.pwd = this.loginForm.pwd
      this.$store.dispatch('authStore/login', cred)
          .then(() => {
            let searchParams = new URLSearchParams(window.location.search);
            if (searchParams.has("redirect")) {
              this.$router.push({ path: `${searchParams.get("redirect")}` });
            } else this.$router.push({ path: "/home" });
          })
          .catch(() => {
            // login failed
            this.snackbar = true
          })
    }
  }
}
</script>

<style scoped>

</style>